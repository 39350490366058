<template>
    <div class="screen">

        <div ref="slider">
            <div class="animation-wrapper slide">
                <div id="animation">
                    <Lottie :options="lottieOptions"/>
                </div>
            </div>
            <div class="second-wrapper slide" v-for="(slide, index) in slides" :key="slide.id">
                <!-- TODO: Evaluate router-link -->
                <a :href="slide.URL">
                    <div class="second">
                        <img class="logo" src="@/assets/REACH_Logo.svg" alt="REACH_Logo">
                        <picture>
                            <template v-for="[format, set] of Object.entries(srcsets[index])">
                                <source v-if="format !== originalFormats[index]" :srcset="set" sizes="(max-width: 1100px) 300vw, 100vw" :type="'image/' + format.slice(1)">
                            </template>
                            <img class="background" :srcset="srcsets[index][originalFormats[index]]" :type="'image/' + originalFormats[index].slice(1)" sizes="(max-width: 1100px) 300vw, 100vw" :alt="slide.Image.data.attributes.alternativeText">
                        </picture>
                    </div>
                </a>
            </div>
        </div>

        <div class="slider-ui">
            <div class="slider-arrows">
                <div class="slider-prev" v-on:click="prevSlide">
                    <img src="@/assets/arrow_left.svg" width="21px" height="40px" alt="Previous Slide"/>
                </div>
                <div class="slider-next" v-on:click="nextSlide">
                    <img src="@/assets/arrow_right.svg" width="21px" height="40px" alt="Next Slide"/>
                </div>
            </div>
            <div class="slider-dots">
                <div v-for="slide in slides.length + 1" 
                    class="dot" 
                    :class="slideActive(slide)"
                    v-on:click="selectSlide(slide)"
                ></div>
            </div>
        </div>

        <img v-on:click="scrollDown" class="scroll" src="@/assets/arrow_down.svg" alt="Runterscrollen Icon">
    </div>

</template>


<script>
    import Flickity from 'flickity'
    import logoAnimationJson from '@/assets/logo.json'

    export default {
        name: 'HomeSlider',

        props: [
            'slides'
        ],

        components: {
            components: () => import('vue-lottie').then(m => m.default),
        },

        data() {
            return {
                home: null,
                slider: null,
                loading: true,
            }
        },

        mounted() {
            this.initFlickity()
        },

        computed: {
            lottieOptions() {
                return {
                    animationData: logoAnimationJson,
                    renderer: 'canvas',
                    autoplay: true,
                    loop: true
                }
            },
            srcsets(){
                let srcsets = []
                for (const slide of this.slides) {
                    srcsets.push(this.getImageSrcsets(slide.Image.data.attributes))
                }
                return srcsets
            },
            
            hasWebp(){
                let hasWebps = []
                for (const srcset of this.srcsets) {
                    hasWebps.push(srcset['.webp'] ? true : false)
                }
                return hasWebps
            },

            originalFormats(){
                let originalFormats = []
                for (const srcset of this.srcsets) {
                    for (const[format, _] of Object.entries(srcset)) {
                        if (format !== '.webp') {
                            originalFormats.push(format)
                        }
                    }
                }
                
                return originalFormats
            },
        },

        methods: {
            initFlickity() {
                var self = this
                this.$nextTick(() => {
                    self.slider = new Flickity(self.$refs.slider, {
                        prevNextButtons: false,
                        pageDots: false,
                        autoPlay: 7000,
                        wrapAround: true,
                        pauseAutoPlayOnHover: false
                    })
                })
            },

            prevSlide() {
                this.slider.previous()
            },

            nextSlide() {
                this.slider.next()
            },

            slideActive(slide) {
                if(this.slider){
                    return {
                        active: this.slider.selectedIndex == slide - 1
                    }
                }
            },

            selectSlide(slide) {
                this.slider.select(slide - 1)
            },

            scrollDown() {
                window.scrollTo(0, window.innerHeight)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .slide {
        width: 100%;
    }

    .scroll {
        width: 38px;
        margin: 0 auto;
        padding: 10px;
        position: absolute;
        left: 0;
        right: 0;
        top: 90vh;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
            transition: opacity 200ms;
        }
    }
</style>
