<template>
    <div>
        <div ref="slider">
            <div class="slide" v-for="(slide, index) in slides" :key="slide.id">
                <!-- TODO: Evaluate router-link -->
                <a :href="slide.URL">
                    <div class="slide-image-container">
                        <picture>
                            <template v-for="[format, set] of Object.entries(srcsets[index])">
                                <source v-if="format !== originalFormats[index]" :srcset="set" sizes="500vw" :type="'image/' + format.slice(1)">
                            </template>
                            <img :srcset="srcsets[index][originalFormats[index]]" :type="'image/' + originalFormats[index].slice(1)" sizes="500vw" :alt="slide.Image.data.attributes.alternativeText">
                        </picture>
                    </div>
                    <div class="slide-text">
                        <div class="title">
                            {{ slide.Title }}
                        </div>
                        <div class="caption">
                            {{ slide.Caption }}
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="slider-ui">
            <div class="slider-arrows">
                <div class="slider-prev" v-on:click="prevSlide">
                    <img src="@/assets/arrow_left.svg" width="21px" height="40px" alt="Previous Slide"/>
                </div>
                <div class="slider-next" v-on:click="nextSlide">
                    <img src="@/assets/arrow_right.svg" width="21px" height="40px" alt="Next Slide"/>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import Flickity from 'flickity'

    export default {
        name: 'GenericSlider',

        props: [
            'slides'
        ],

        data() {
            return {
                slider: null
            }
        },

        mounted() {
            var self = this
            this.$nextTick(() => {
                self.slider = new Flickity(self.$refs.slider, {
                    prevNextButtons: false,
                    pageDots: true,
                    autoPlay: 7000,
                    wrapAround: true,
                    pauseAutoPlayOnHover: false,
                    imagesLoaded: true
                })
            })
        },

        computed: {
            srcsets(){
                let srcsets = []
                for (const slide of this.slides) {
                    srcsets.push(this.getImageSrcsets(slide.Image.data.attributes))
                }
                return srcsets
            },
            
            hasWebp(){
                let hasWebps = []
                for (const srcset of this.srcsets) {
                    hasWebps.push(srcset['.webp'] ? true : false)
                }
                return hasWebps
            },

            originalFormats(){
                let originalFormats = []
                for (const srcset of this.srcsets) {
                    for (const[format, _] of Object.entries(srcset)) {
                        if (format !== '.webp') {
                            originalFormats.push(format)
                        }
                    }
                }
                
                return originalFormats
            },
        },

        methods: {
            prevSlide() {
                this.slider.previous()
            },

            nextSlide() {
                this.slider.next()
            },

            slideActive(slide) {
                if(this.slider){
                    return {
                        active: this.slider.selectedIndex == slide - 1
                    }
                }
            },

            selectSlide(slide) {
                this.slider.select(slide - 1)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .slide {
        position: relative;
        width: 100%;
            
        .slide-image-container {
            position: relative;
            height: 600px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include breakpoint('tablet') {
                height: 412px;
            }
            @include breakpoint('mobile') {
                height: 333px;
            }
        }

        .slide-text {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-bottom: 50px;

            .title {
                font-family: getFontFamily('textHeader');
                font-size: getFontSize('textHeader', 'desktop');
                text-align: center;
                max-width: 80%;

                @include breakpoint('tablet') {
                    font-size: getFontSize('textHeader', 'tablet');
                }
                @include breakpoint('mobile') {
                    font-size: getFontSize('textHeader', 'mobile');
                }
            }

            .caption {
                font-family: getFontFamily('headerBlock');
                font-size: getFontSize('headerBlock', 'desktop');
                text-align: center;
                max-width: 80%;

                @include breakpoint('tablet') {
                    font-size: getFontSize('headerBlock', 'tablet');
                }
                @include breakpoint('mobile') {
                    font-size: getFontSize('headerBlock', 'mobile');
                }
            }
        }
    }
</style>
