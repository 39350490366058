<template>
    <section :id="cmsBlock.anchorTag">
        <div v-if="cmsBlock.sliderStyle == 'fullscreen'">
            <FullscreenSlider :slides="localize(cmsBlock)" />
        </div>
        <div v-if="cmsBlock.sliderStyle == 'generic'">
            <GenericSlider :slides="localize(cmsBlock)" />
        </div>
        <div v-if="cmsBlock.sliderStyle == 'home_top'">
            <HomeSlider :slides="localize(cmsBlock)" />
        </div>
        <div v-if="cmsBlock.sliderStyle == 'advanced'">
            <AdvancedSlider :slides="localize(cmsBlock)" />
        </div>
    </section>
</template>

<script>
    import FullscreenSlider from '@/components/PageBuilder/Elements/FullscreenSlider'
    import GenericSlider from '@/components/PageBuilder/Elements/GenericSlider'
    import HomeSlider from '@/components/PageBuilder/Elements/HomeSlider'
    import AdvancedSlider from '@/components/PageBuilder/Elements/AdvancedSlider'

    export default {
        name: 'Slider',

        props: [
            'cmsBlock'
        ],

        components: {
            FullscreenSlider,
            GenericSlider,
            HomeSlider,
            AdvancedSlider
        }
    }
</script>

<style lang="scss" scoped>
    section {
        padding: 0;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
</style>
