<template>
    <div class="advanced-slider-wrapper">  
        <div class="advanced-slider" ref="slider">
            <div class="slide" v-for="(slide, index) in slides" :key="slide.id">
                <div class="slide-image-container">
                    <picture>
                        <template v-for="[format, set] of Object.entries(srcsets[index])">
                            <source v-if="format !== originalFormats[index]" :srcset="set" sizes="100vw" :type="'image/' + format.slice(1)">
                        </template>
                        <img class="slide-image" :srcset="srcsets[index][originalFormats[index]]" :type="'image/' + originalFormats[index].slice(1)" sizes="100vw" :alt="slide.Image.data.attributes.alternativeText">
                    </picture>
                </div>
                <div class="slide-details-wrapper">
                    <div class="slide-details-content">
                        <div class="advanced-content-wrapper" :class="{'bg-dark': slide.Dark_Style, 'bg-light': !slide.Dark_Style}">
                            <div class="content-block">
                                <div v-html="md(slide.Content)" class="advanced_content"></div>
                                <a v-if="slide.Button_Text && !isInternalLink(slide.Button_URL)" :href="slide.Button_URL">
                                    <button :class="{'button-dark': !slide.Dark_Style, 'button-light': slide.Dark_Style}">{{slide.Button_Text}}</button>
                                </a>
                                <router-link v-if="slide.Button_Text && isInternalLink(slide.Button_URL)" :to="slide.Button_URL">
                                    <button :class="{'button-dark': !slide.Dark_Style, 'button-light': slide.Dark_Style}">{{slide.Button_Text}}</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="slider-ui">
            <div class="slider-arrows">
                <div class="slider-prev" v-on:click="prevSlide">
                    <img src="@/assets/arrow_left.svg" width="21px" height="40px" alt="Previous Slide"/>
                </div>
                <div class="slider-next" v-on:click="nextSlide">
                    <img src="@/assets/arrow_right.svg" width="21px" height="40px" alt="Next Slide"/>
                </div>
            </div>
            <div class="slider-dots">
                <div v-for="slide in slides.length" 
                    class="dot" 
                    :class="slideActive(slide)"
                    v-on:click="selectSlide(slide)"
                ></div>
            </div>
        </div>
        <img v-on:click="scrollDown" class="scroll" src="@/assets/arrow_down.svg" alt="Runterscrollen Icon">
    </div>
</template>

<script>
    import Flickity from 'flickity'
    import { isMobileDevice } from '@/helper'

    export default {
        name: 'AdvancedSlider',

        props: [
            'slides'
        ],

        //components: () => import('vue-lottie').then(m => m.default),

        data() {
            return {
                home: null,
                slider: null,
                loading: true,
            }
        },

        mounted() {
            this.initFlickity()
        },

        computed: {
            srcsets(){
                let srcsets = []
                for (const slide of this.slides) {
                    srcsets.push(this.getImageSrcsets(slide.Image.data.attributes))
                }
                return srcsets
            },
            
            hasWebp(){
                let hasWebps = []
                for (const srcset of this.srcsets) {
                    hasWebps.push(srcset['.webp'] ? true : false)
                }
                return hasWebps
            },

            originalFormats(){
                let originalFormats = []
                for (const srcset of this.srcsets) {
                    for (const[format, _] of Object.entries(srcset)) {
                        if (format !== '.webp') {
                            originalFormats.push(format)
                        }
                    }
                }
                
                return originalFormats
            },
        },

        methods: {
            onImgLoad() {
                this.slider.resize()
            },
            initFlickity() {
                var self = this
            this.$nextTick(() => {
                self.slider = new Flickity(self.$refs.slider, {
                    prevNextButtons: false,
                    pageDots: false,
                    autoPlay: 70000,
                    wrapAround: true,
                    adaptiveHeight: true,
                    pauseAutoPlayOnHover: false,
                    imagesLoaded: true,
                    lazyLoad: true
                })
            })
            },

            isMobileDevice: isMobileDevice,

            prevSlide() {
                this.slider.player.stop()
                this.slider.previous()
                this.slider.player.play()
            },

            nextSlide() {
                this.slider.player.stop()
                this.slider.next()
                this.slider.player.play()
            },

            slideActive(slide) {
                if(this.slider){
                    return {
                        active: this.slider.selectedIndex == slide - 1
                    }
                }
            },

            selectSlide(slide) {
                this.slider.player.stop()
                this.slider.select(slide - 1)
                this.slider.player.play()
            },

            scrollDown() {
                window.scrollTo(0, window.innerHeight)
            }

        }
    }
</script>

<style lang="scss" scoped>

.scroll {
    width: 38px;
    margin: 0 auto;
    padding: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: 90vh;
    filter: invert(0.15);
    cursor: pointer;
    @include breakpoint('tablet-xs'){
        top:unset;
        bottom: -10px;
    }
}
.advanced-slider-wrapper {
    position: relative;
}

.advanced-slider {
    overflow: hidden;
    position: relative;
    height: 100vh;

    .slide {
        position: relative;
        width: 100%;
        overflow: hidden;

        .slide-image-container {
            position: relative;
            height:100vh;

            @include breakpoint('mobile'){
                height: unset;
                //max-height: 100vh;
            }
        }

        .slide-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            @include breakpoint('mobile'){
                object-fit: contain;
            }
        }

        .slide-details-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            background: unset;
            top:0;
            padding: 50px;
            padding-bottom: 100px;

            @include breakpoint('mobile'){
                position:relative;
                min-height: 500px;
                background-color: white;
                width: 100%;
                padding: 0!important;
                margin-top: -100px;
            }
            
            @media only screen and (max-width: 727px){
                margin-top: 0;
            }

            .slide-details-content {
                margin: 0 auto;
                display: flex;
            }
        }
    }
}

@include breakpoint('mobile') {

    .content-block a {
        width: 100%;
        button{
            width: 100%;
        }
    }
    .advanced-content-wrapper{
        clip-path:polygon(0% 0,100% 11%,100% 85%,0% 99%);
        position: relative;
        width: 100%!important;
        height: auto!important;
        top:0!important;
        left: 0!important;
        margin-top: -65px;
        padding:50px 0px 100px 0px !important;
        &.bg-dark{
            background-color:$timberGreen;
        }
        &.bg-light{
            background-color:white;
        }
    }
    .slide-details-content{
        background-color: white;
        height: 100vh;
    }
    .slider-dots{
        bottom: 50px!important;
    }
}

.advanced-content-wrapper{
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    vertical-align: middle;
    justify-content: center;
    position: absolute;
    width: 740px;
    height: 701px;
    padding:120px 150px 150px 120px;
    overflow-y: hidden;
    top: 8%;
    z-index: 999;
    left: 5%;
    @media only screen and (max-width: 1919px){
        width: 470px;
        height: 531px;
    }
    @media only screen and (min-width: 2500px){
        width: 630px;
        height: 811px;
    }
    @media only screen and (max-width: 1200px){
        width: 370px;
        height: 431px;
    }

    &.bg-dark{
        background-image:url('~@/assets/vector_dark.svg');
        color: white;
        
        .advanced_content{
            ::v-deep p {
                color: white;
                }
            
            ::v-deep h1 {
                color: white;
            }
            ::v-deep h2 {
                color: white;
            }
            ::v-deep h3 {
                color: white;
            }
            ::v-deep h4 {
                color: white;
            }
            ::v-deep h5 {
                color: white;
            }
            ::v-deep h6 {
                color: white;
            }
        }
    }
    &.bg-light{
        background-image:url('~@/assets/vector_white.svg');
        color: $timberGreen;
        .advanced_content{
            ::v-deep p {
                color: $timberGreen;
            }
            ::v-deep h1,
            ::v-deep h2,
            ::v-deep h3,
            ::v-deep h4,
            ::v-deep h5,
            ::v-deep h6 {
                color: $timberGreen;
            }
        }
    }

    .advanced_content{
        ::v-deep h1 {
            font-size: getFontSize('sliderH1', 'desktop');;

            @include breakpoint('tablet') {
                font-size: getFontSize('sliderH1', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('sliderH1', 'mobile');
            }
        }

        ::v-deep h2 {
            font-size: getFontSize('sliderH2', 'desktop');;

            @include breakpoint('tablet') {
                font-size: getFontSize('sliderH2', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('sliderH2', 'mobile');
            }
        }

        ::v-deep h3 {
            font-size: getFontSize('sliderH3', 'desktop');;

            @include breakpoint('tablet') {
                font-size: getFontSize('sliderH3', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('sliderH3', 'mobile');
            }
        }

        ::v-deep h4 {
            font-size: getFontSize('sliderH4', 'desktop');;

            @include breakpoint('tablet') {
                font-size: getFontSize('sliderH4', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('sliderH4', 'mobile');
            }
        }

        ::v-deep h5 {
            font-size: getFontSize('sliderH5', 'desktop');;

            @include breakpoint('tablet') {
                font-size: getFontSize('sliderH5', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('sliderH5', 'mobile');
            }
        }

        ::v-deep h6 {
            font-size: getFontSize('sliderH6', 'desktop');;

            @include breakpoint('tablet') {
                font-size: getFontSize('sliderH6', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('sliderH6', 'mobile');
            }
        }

        @media only screen and (max-width: 1200px){
            overflow: hidden;
        }
        ::v-deep img {
            width: 100%;
            display: block;
            margin-bottom: 20px;
        }
        overflow: hidden;
        max-height: 90%;
        ::v-deep p{
            text-align: left;

            font-size: getFontSize('sliderText', 'desktop');;

            @include breakpoint('tablet') {
                font-size: getFontSize('sliderText', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('sliderText', 'mobile');
            }
        }
    }
}

</style>
<style>
.content-block{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 80%;
    height: 100%;
    vertical-align: middle;
    justify-content: center;
    align-items: flex-start; 
}

@media only screen and (max-width: 825px){
    .content-block{
        width: 60%;
    }
}
@media only screen and (max-width: 620px){
    .content-block{
        width: 80%;
    }
}
@media only screen and (max-width: 420px){
    .content-block{
        width: 80%;
    }
}

.advanced_content h1, 
.advanced_content h2, 
.advanced_content h3,
.advanced_content h4,
.advanced_content h5,
.advanced_content h6{
    text-align: left!important;
}

.slider-ui .slider-dots .dot{
    background-color: #e3e6e7!important;
}

</style>
